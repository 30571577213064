<template>
  <div class="add-new-coupon">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.couponUpdate')"
      :title="$t('messages.couponUpdate')"
      @submitPressed="submitPressed"
    />
    <coupons-form
      v-if="coupon"
      :couponObject="coupon"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CouponsForm from "./components/CouponsForm.vue";

export default {
  components: {
    TitleBar,
    CouponsForm,
  },
  data() {
    return {
      coupon: null,
      action: null,
    };
  },
  created() {
    this.loadCoupon();
  },
  methods: {
    loadCoupon() {
      const id = this.$route.params.id;
      this.$Coupons.getResource({ id }, 'coupon_edit').then((response) => {
        this.coupon = response.data;
        this.coupon.typeSelect = {
          label: this.$helper.getEnumTranslation('coupon_type', this.coupon.type, this.$i18n.locale),
          id: this.coupon.type
        }
        if(this.coupon.resort) {
          this.coupon.resortSelect = {
            label: this.coupon.resort.name,
            id: this.coupon.resort['@id']
          }
        } else {
          this.coupon.resortSelect = null
        }
        this.coupon.fromDate = new Date(this.coupon.fromDate)
        this.coupon.toDate = new Date(this.coupon.toDate)
      });
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
